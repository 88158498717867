import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import("@/views/home/Home.vue")
  },
  {
    path: '/home',
    component: () => import("@/views/home/Home.vue")
  },
  {
    path: '/introduction',
    component: () => import("@/views/introduction/Introduction.vue")
  },
  {
    path: '/develop',
    component: () => import("@/views/develop/Develop.vue")
  },
  {
    path: '/contact',
    component: () => import("@/views/contact/Contact.vue")
  },
  {
    path: '/care',
    component: () => import("@/views/care/Care.vue")
  },
  {
    path: '/notice',
    component: () => import("@/views/notice/Notice.vue")
  },
  {
    path: '/activity',
    component: () => import("@/views/activity/Activity.vue")
  },
  {
    path: '/term',
    component: () => import("@/views/term/Term.vue")
  },
  {
    path: '/bangoods',
    component: () => import("@/views/banGoods/BanGoods.vue")
  },
  {
    path: '/login',
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: '/register',
    component: () => import("@/views/register/Register.vue")
  },
  {
    path: '/foundpassword',
    component: () => import("@/views/foundPassword/FoundPassword.vue")
  },
  {
    path: '/arts',
    component: () => import("@/views/arts/Art.vue")
  },
  {
    path: '/question',
    component: () => import("@/views/question/Question.vue")
  },
  {
    path: '/taxTaust',
    component: () => import("@/views/taxTaust/TaxTaust.vue")
  },
  {
    path: '/shop',
    component: () => import("@/views/shop/Shop.vue")
  },
  {
    path: '/waybill',
    component: () => import("@/views/waybill/Waybill.vue")
  },
  {
    path: '/order',
    component: () => import("@/views/order/Order.vue")
  },
  {
    path: '*',
    component: () => import("@/views/notfound/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
